import { useEffect, useState } from 'react';
import { put, post, patch, get } from 'utils/axiosRequests';
import { globalAction, dispatch } from 'store';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
// ==============================|| CONFIG - LOCAL STORAGE ||============================== //

export default function useHandleForm(
    url,
    pageBehaviour = 'Add',
    formikInitialValues,
    validationSchema,
    changeFormSubmitValue = null,
    customReset = null
) {
    const params = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const { currentPage, recordPerPage, searchStr, currentData } = useSelector((state) => state.listing);

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema,
        onSubmit: (values, { resetForm }) => {
            if (changeFormSubmitValue !== null) values = changeFormSubmitValue(values);
            if (pageBehaviour === 'Add') {
                addData(values, resetForm);
            } else {
                updateData(values);
            }
        }
    });

    const getCurrentDataByID = () => {
        setIsLoading(true); // Loading Start
        get(url)
            .then((response) => {
                console.log(response.data);
                dispatch(globalAction('SET_CURRENT_DATA', { currentData: response.data }));
                setIsLoading(false); // Loading End
            })
            .catch((err) => {
                setIsLoading(false); // Loading End
            });
    };

    useEffect(() => {
        if (currentData && pageBehaviour !== 'Add') {
            Object.keys(formikInitialValues).forEach((key) => {
                console.log(key, currentData.key);
                formik.setFieldValue(key, currentData[key]);
            });
        } else if (!currentData && pageBehaviour !== 'Add') {
            getCurrentDataByID();
        }
    }, [currentData]);

    function addData(value, resetForm) {
        setIsLoading(true); // Loading Start
        post(url, value)
            .then((res) => {
                if (res?.type === 'validationError') {
                    res.errors.forEach((error) => {
                        formik.setFieldError(error.param, error.msg);
                    });
                } else {
                    resetForm();
                }
            })
            .catch((err) => {
                if (err?.type === 'validationError') {
                    err.errors.forEach((error) => {
                        formik.setFieldError(error.param, error.msg);
                    });
                }
            })
            .finally(() => {
                setIsLoading(false); // Loading End
                if (customReset) customReset();
            });
    }

    function updateData(value) {
        setIsLoading(false); // Loading Start
        put(`${url}`, value)
            .then((res) => {
                if (res?.type === 'validationError') {
                    res.errors.forEach((error) => {
                        formik.setFieldError(error.param, error.msg);
                    });
                }
            })
            .catch((err) => {
                if (err?.type === 'validationError') {
                    err.errors.forEach((error) => {
                        console.log(error);
                        formik.setFieldError(error.param, error.msg);
                    });
                }
            })
            .finally(() => {
                setIsLoading(false); // Loading End
                if (customReset) customReset();
            });
    }

    return [formik, isLoading, currentData, currentPage, searchStr, recordPerPage];
}
