import { lazy } from 'react';
// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import UpdateProfile from 'views/profile/UpdateProfile';
import UpdateSecurity from 'views/profile/UpdateSecurity';

// dashboard routing
const Dashboard = Loadable(lazy(() => import('views/dashboard')));

// Profile Routing
const AppProfile = Loadable(lazy(() => import('views/profile/Profile')));

// Customer Routing
const AppCustomerList = Loadable(lazy(() => import('views/customer/CustomerList')));
const AppUpdateCustomer = Loadable(lazy(() => import('views/customer/UpdateCustomer')));
const AppCustomerDetails = Loadable(lazy(() => import('views/customer/CustomerDetails')));
const AppCreateDashboard = Loadable(lazy(() => import('views/customer/CreateDashboard')));
const AppSelectPackage = Loadable(lazy(() => import('views/customer/SelectPackage')));
const AppDashboardCreation = Loadable(lazy(() => import('views/customer/DashboardCreation')));

// User Routing
const AppUserList = Loadable(lazy(() => import('views/user/UserList')));
const AppUpdateUser = Loadable(lazy(() => import('views/user/UpdateUser')));

// Subscription Package Routing
const AppPackageList = Loadable(lazy(() => import('views/package/PackageList')));
const AppUpdatePackage = Loadable(lazy(() => import('views/package/UpdatePackage')));
const AppPreviewPackage = Loadable(lazy(() => import('views/package/PackagePreview')));

// Subscriptions Routing
const AppSubscription = Loadable(lazy(() => import('views/subscriptions/SubscriptionList')));

// Orders Routing
const AppOrders = Loadable(lazy(() => import('views/orders/OrderList')));

// Reports Routing
const AppReports = Loadable(lazy(() => import('views/report/Reports')));

// Transactions Routing
const AppTransactionsList = Loadable(lazy(() => import('views/transaction/TransactionList')));

// Promos Routing
const AppPromoList = Loadable(lazy(() => import('views/promo/PromoList')));
const AppUpdatePromo = Loadable(lazy(() => import('views/promo/UpdatePromoCode')));

// Tickets Routing
const AppTickets = Loadable(lazy(() => import('views/ticket/SupportTickets')));

// FAQs Routing
const AppFaqs = Loadable(lazy(() => import('views/faqs/Faqs')));
const AppFaqUpdate = Loadable(lazy(() => import('views/faqs/UpdateFaq')));

// Contact Routing
const AppContact = Loadable(lazy(() => import('views/contact/Contact')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        // {
        //     path: '/',
        //     element: <Dashboard />
        // },
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/customers',
            element: <AppCustomerList />
        },
        {
            path: '/customers/add',
            element: <AppUpdateCustomer pageBehaviour="Add" />
        },
        {
            path: '/customers/:customerID/edit',
            element: <AppUpdateCustomer pageBehaviour="Edit" />
        },
        {
            path: '/customers/:customerID',
            element: <AppCustomerDetails pageBehaviour="View" />,
            children: [
                {
                    path: '/customers/:customerID/create-dashboard',
                    element: <AppCreateDashboard />
                },
                {
                    path: '/customers/:customerID/select-package',
                    element: <AppSelectPackage />
                },
                {
                    path: '/customers/:customerID/dashboard-creation',
                    element: <AppDashboardCreation />
                }
            ]
        },
        {
            path: '/users',
            element: <AppUserList />
        },
        {
            path: '/users/add',
            element: <AppUpdateUser pageBehaviour="Add" />
        },
        {
            path: '/users/:userID/edit',
            element: <AppUpdateUser pageBehaviour="Edit" />
        },
        {
            path: '/profile',
            element: <AppProfile />,
            children: [
                {
                    path: '/profile',
                    element: <UpdateProfile />
                },
                {
                    path: '/profile/update-profile',
                    element: <UpdateProfile />
                },
                {
                    path: '/profile/update-security',
                    element: <UpdateSecurity />
                }
            ]
        },
        {
            path: '/packages',
            element: <AppPackageList />
        },
        {
            path: '/packages/add',
            element: <AppUpdatePackage pageBehaviour="Add" />
        },
        {
            path: '/packages/:packageID/edit',
            element: <AppUpdatePackage pageBehaviour="Edit" />
        },
        // {
        //     path: '/packages/:packageID',
        //     element: <AppPreviewPackage pageBehaviour="View" />
        // },
        // {
        //     path: '/orders',
        //     element: <AppOrders />
        // },
        {
            path: '/subscriptions',
            element: <AppSubscription />
        },
        // {
        //     path: '/reports',
        //     element: <AppReports />
        // },
        // {
        //     path: '/transactions',
        //     element: <AppTransactionsList />
        // },
        // {
        //     path: '/promo',
        //     element: <AppPromoList />
        // },
        // {
        //     path: '/promo/add',
        //     element: <AppUpdatePromo />
        // },
        // {
        //     path: '/promo/:promoID/edit',
        //     element: <AppUpdatePromo />
        // },
        // {
        //     path: '/support-tickets',
        //     element: <AppTickets />
        // },
        {
            path: '/faqs',
            element: <AppFaqs />
        },
        {
            path: '/faqs/add',
            element: <AppFaqUpdate pageBehaviour="Add" />
        },
        {
            path: '/faqs/:faqID/edit',
            element: <AppFaqUpdate pageBehaviour="Edit" />
        },
        {
            path: '/contact-us',
            element: <AppContact />
        }
    ]
};

export default MainRoutes;
