// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconReceipt,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconMail
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconReceipt,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconShoppingCartDiscount,
    IconTicket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconMail
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard'
        },
        {
            id: 'users',
            title: <FormattedMessage id="users" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/users'
        },
        {
            id: 'customers',
            title: <FormattedMessage id="customers" />,
            type: 'item',
            icon: icons.IconUsers,
            url: '/customers'
        },
        {
            id: 'packages',
            title: <FormattedMessage id="packages" />,
            type: 'item',
            icon: icons.IconChecklist,
            url: '/packages'
        },
        {
            id: 'subscriptions',
            title: <FormattedMessage id="subscriptions" />,
            type: 'item',
            icon: icons.IconReceipt,
            url: '/subscriptions'
        },
        // {
        //     id: 'reports',
        //     title: <FormattedMessage id="reports" />,
        //     type: 'item',
        //     icon: icons.IconFileAnalytics,
        //     url: '/reports'
        // },
        // {
        //     id: 'transactions',
        //     title: <FormattedMessage id="transactions" />,
        //     type: 'item',
        //     icon: icons.IconReceipt,
        //     url: '/transactions'
        // },
        // {
        //     id: 'support-tickets',
        //     title: <FormattedMessage id="support-tickets" />,
        //     type: 'item',
        //     icon: icons.IconTicket,
        //     url: '/support-tickets'
        // },
        {
            id: 'faqs',
            title: <FormattedMessage id="faqs" />,
            type: 'item',
            icon: icons.IconQuestionMark,
            url: '/faqs'
        },
        {
            id: 'contact',
            title: <FormattedMessage id="contact" />,
            type: 'item',
            icon: icons.IconMail,
            url: '/contact-us'
        },
        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                {
                    id: 'profile',
                    title: <FormattedMessage id="profile" />,
                    type: 'item',
                    icon: icons.IconUserCircle,
                    url: '/profile'
                }
            ]
        }
    ]
};

export default pages;
