import React, { useState } from 'react';

// Material UI
import { Stack, Grid, TextField, Button, Avatar, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// import Project
import useAuth from 'hooks/useAuth';

// assets
import Avatar1 from 'assets/images/users/avatar-1.png';
import { useTheme } from '@mui/material/styles';
import useHandleForm from 'hooks/useHandleForm';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { API_URL } from 'config';

// Validation
const validationSchema = yup.object({
    first_name: yup.string().required('First Name is required'),
    phone_number: yup.string().required('First Name is required'),
    email_address: yup.string().required('First Name is required')
});

// ==============================|| PROFILE UPDATE ||============================== //

const UpdateProfile = () => {
    const { user, updateProfile } = useAuth();
    const theme = useTheme();
    const [currentProfileImage, setCurrentProfileImage] = useState(`${API_URL}/${user?.user_avatar}`);
    const formik = useFormik({
        initialValues: {
            first_name: user?.first_name,
            last_name: user?.last_name,
            phone_number: user?.phone_number,
            email_address: user?.email_address
        },
        validationSchema,
        onSubmit: (values) => {
            updateProfile(user.id, values);
        }
    });

    const setCurrentImage = (e) => {
        const formData = new FormData();
        formData.append('user_avatar', e.currentTarget.files[0]);
        e.preventDefault();
        updateProfile(user.id, formData);
        setCurrentProfileImage(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item sm={6} md={4}>
                <SubCard title="Profile Picture" contentSX={{ textAlign: 'center' }} sx={{ backgroundColor: theme.palette.primary.dark }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Avatar alt={user?.first_name} src={currentProfileImage} sx={{ width: 105, height: 105, margin: '0 auto' }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="subtitle2" align="center">
                                Upload/Change Your Profile Image
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" component="label">
                                Change Profile
                                <input
                                    hidden
                                    accept="image/*"
                                    type="file"
                                    id="user_avatar"
                                    name="user_avatar"
                                    onChange={(event) => {
                                        setCurrentImage(event);
                                    }}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
            <Grid item sm={6} md={8}>
                <SubCard title="Edit Account Details" sx={{ backgroundColor: theme.palette.primary.dark }}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label="First Name*"
                                    autoComplete="off"
                                    value={formik.values.first_name}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={formik.handleChange}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="phone_number"
                                    name="phone_number"
                                    label="Phone Number*"
                                    type="number"
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.phone_number}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                                <TextField
                                    fullWidth
                                    id="email_address"
                                    name="email_address"
                                    label="Email address*"
                                    type="email"
                                    autoComplete="off"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{ readOnly: true }}
                                    value={formik.values.email_address}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                                    helperText={formik.touched.email_address && formik.errors.email_address}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row">
                                    <AnimateButton>
                                        <Button variant="contained" type="submit">
                                            Change Details
                                        </Button>
                                    </AnimateButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </form>
                </SubCard>
            </Grid>
        </Grid>
    );
};

export default UpdateProfile;
